@import '../../../utils/utils.scss';
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.holder {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .desktopGrid {
    width: 100%;
    height: 100%;
    padding: 1%;
    overflow: hidden;
    .container {
      width: 100%;
      height: 100%;
      ion-col {
        padding: 0 1%;
        height: 100%;
      }
      .leftBar {
        overflow-y: scroll;
        padding: 1%;
        .homeGraphs {
          width: 100%;
          height: 65vh !important;
          display: flex;
          align-items: center;
          overflow: hidden;
          justify-content: space-between;

          .bargraph,
          .piechart {
            height: 100%;
            border: 1px solid #1a1a1a90;
            padding: 1%;
            border-radius: 15px;
            .label {
              width: 100%;
              height: auto;
              padding: 0 3%;
              @include typography-primary(1em, bold, #1a1a1a);
            }
            .chartHolder {
              width: 100%;
              height: 95%;
            }
          }
          .bargraph {
            width: 64%;
          }
          .piechart {
            width: 35%;
          }
        }
        .products {
          width: 100%;
          min-height: 30%;
        }
      }
      .rightBar {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .mobileGrid {
    width: 100%;
    height: 100%;
    padding: 1%;
    overflow-y: scroll;
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
      }
    }
    .currentStore {
      height: 20vh;
      ion-col {
        padding: 1% 2%;
      }
    }
    .homeGraphs {
      height: auto;
      ion-col {
        width: 100%;
        height: auto;
        padding: 2%;

        .bargraph,
        .piechart {
          height: 65vh;
          width: 100%;
          border: 1px solid #1a1a1a90;
          padding: 2%;
          border-radius: 15px;
          overflow: hidden;
          margin: 3px 0;
          .label {
            width: 100%;
            height: 10%;
            padding: 0 3%;
            @include typography-primary(1em, bold, #1a1a1a);
          }
          .chartHolder {
            width: 100%;
            height: 90%;
          }
        }
        .bargraph {
        
        }
        .piechart {
       
        }
      }
    }
    .products {
      height: fit-content;
      ion-col {
        width: 100%;
        height: 100%;
        padding: 2%;
        .holder {
          width: 100%;
          height: fit-content;
        }
      }
    }
  }
}
