ion-button {
  --border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-transform: none;
  --padding-end: 20px;
  --padding-start: 20px;
  --box-shadow: none;
  min-height: 40px;

  ion-spinner {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }
}