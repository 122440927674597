@import '../../utils/utils.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .storebar_grid {
    width: 100%;
    height: 100%;
    padding: 1%;
    overflow-y:scroll;
    .store,
    .info, .currentStore {
      width: 100%;
      margin-top:10px;
      ion-col {
        height: 100%;
      }
    }
    .currentStore{
      height:18vh;
      ion-col{
        padding:1% !important;
        
      }
    }
    .store {
      height: 40vh;
      .storeCard {
        width: 100%;
        height: 100%;
        border: 1px solid #1a1a1a95;
        border-radius: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        .s_grid {
          width: 100%;
          height: 100%;
          padding: 2%;

          .head,
          .title,
          .list {
            width: 100%;
            ion-col {
              height: 100%;
            }
          }
          .head {
            height: 50%;
            ion-col {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .img {
              aspect-ratio : 1 / 1;
              height: 100%;
              width: auto;
              border-radius: 50%;
              background: #fff;
              border: 1px solid #1a1a1a95;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .title {
            height: 15%;
            ion-col {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .storeName {
              @include typography-primary(1.1em, bold, #1a1a1a);
            }
          }
          .list {
            height: 35%;
            padding: 0 3%;
            .listings,
            .views {
              width: 100%;
              height: 30%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .label {
                @include typography-primary(0.8em, 800, rgba(0, 0, 0, 0.6));
              }
            }
            .listings {
              .tListings {
                @include typography-primary(0.9em, bold, #A94064);
              }
            }
            .views {
              .tViews {
                @include typography-primary(0.9em, bold, #A94064);
              }
            }
          }
        }
      }
    }
    .info {
      height:auto;
      .recent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2% 0 0 0;
        .label {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text {
            height: 100%;
            width: auto;
            @include typography-primary(1em, bold, rgba(0, 0, 0, 0.75));
          }
          .link {
            position: relative;
            height: 100%;
            width: 20%;
            cursor: pointer;
            @include typography-primary(1em, bold, #A94064);
            justify-content: center;
          }
        }
        .list {
          width: 100%;
          height: 90%;
          padding: 1%;
          overflow-y: scroll;
          
          .listItem {
            position:relative;
            width: 100%;
            height: 15vh;
            overflow: hidden;
            border: 1px solid #1a1a1a93;
            border-radius: 10px;
            padding: 2% 1%;
            display: flex;
            justify-content: space-evenly;
            cursor:pointer;
            margin:5px 0;
            .img {
              position:relative;
              height: 100%;
              aspect-ratio:1/1;
              width:auto;
              border-radius: 50%;
              background: #fff;
              border: 1px solid #1a1a1a95;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .text {
              height: 100%;
              width: 70%;
              .title{
                height:60%;
                @include typography-primary(0.8em,bold,#1a1a1a);
                align-items:start;
              }
              .price{
                height:40%;
                @include typography-primary(1em,bold,#A94064);
              }
            }
          }
        }
      }
    }
  }
}
