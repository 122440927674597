@import '../../utils/utils.scss';
.slide-menu {
  --height: 100%;
  // --width: 100%; // uncomment to make menu full width
}

.user-slidemenu,
.seller-slidemenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  .header {
    border-bottom: 1px solid rgba(26, 26, 26, .306);
    ion-row {
      ion-col {
        display: flex;
        align-items: center;
      }
    }
    .logo-close-row {
      .logo{
        font-size: 1.3em;
        display: flex;
        align-items: center;
        ion-icon {
          margin: 0 5px;
        }
        span {
          font-weight: 700;
          color: var(--ion-color-primary);
          font-size: 1.3em;
        }
        .span2 {
          color: var(--ion-color-secondary);
          font-style: italic;
        }
      }
      .close {
        display: flex;
        align-items: center;
        ion-icon {
          font-size: 1.5em;
          width: 40px;
          height: 40px;
          --ionicon-stroke-width: 35px;
        }
      }
    }
    .user-details-row {
      ion-item {
        --padding-start: 5px;
        ion-label {
          font-weight: 600;
          font-size: 1.1rem;
        }
        ion-icon {
          font-size: 2rem;
          margin-right: 10px;
          color: var(--ion-color-primary);
        }
      }
    }
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    height: 100%;
    ion-item {
      color: var(--ion-color-primary);
      font-size: 1.1rem;
      font-weight: 600;
      margin: 2px 0;
      ion-icon {
        color: var(--ion-color-primary);
        margin: 12px 16px 12px 5px;
        font-size: 2rem;
      }
      ion-label {
        color: var(--ion-color-primary);
        font-weight: 600;
      }
      .chevron {
        font-size: 1.1rem;
        margin: 0;
      }
    }
    .selected {
      color: var(--ion-color-secondary);
      ion-icon {
        color: var(--ion-color-secondary);
      }
      ion-label {
        color: var(--ion-color-secondary);
      }
    }
    ion-item:last-child,
    .menu-item-wrapper:last-child {
      margin-top: auto;
    }
    .sub-menu-list {
      padding: 0px 30px;

      ion-item {
        font-size: 0.925rem;
        margin: 0;
        ion-icon {
          margin: 5px 10px 5px 2px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.user-slidemenu {
  padding-bottom: 0;
}

.menuFooter {
  text-align: center;
  color: rgba(26, 26, 26, 0.306);
  font-size: 0.8rem;
  font-weight: 400;
}
