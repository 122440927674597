.searchbar {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 0.5%;
  .cat {
    width: 24%;
    height: 100%;
    border-radius: 100px;
    color: #fff;
    background-color: #A94064;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size:1em;
    padding: 2%;
  }
  .input {
    width: 49%;
    height: 100%;
    margin: 0 2% 0 1%;
    --padding-bottom: 5px;
    --padding-top: 5px;
    --padding-end: 5px;
    --color: #1a1a1a;
    input {
      height: 80%;
      font-size: 1em;
    }
  }
  .btn {
    width: 24%;
    height: 100%;
    margin: 0;
    padding: 0 !important;
    --border-radius: 100px;
    font-weight: 600;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
}
