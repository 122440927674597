.grid{
    --ion-grid-width-xl: 1800px;
     --ion-grid-width-lg: 1200px;
    --ion-grid-width-md: 900px;
    --ion-grid-width-sm: 720px;
    --ion-grid-width-xs: 540px;
      padding: 0 40px;
   }
.Desktop{
    width: 100%;
    .help{
        background-color:  #1a1a1a;
        height: 170px;
        margin-top: -1.5%;
        .divhelp{
            margin-left: 4%;
        }
        h1{
            color: white;
            font-family: Roboto;
            font-size: 45px;
            font-weight: 700;
        }
        .searchflex{
            display: flex;
            gap: 2%;
            align-items: center;
        }
        input{
            width: 1220px;
            height: 45px;
            border-radius: 10px;
        }
        .button{
            width: 250px;
            height: 45px;
        }
    }
    .divsection{
        
        .divdisplay{
        display: flex;
        gap: 30px;
        margin-top: 2%;
        flex-wrap: wrap;
        }
    .divs{
        width: 340px;
        height: 239px;
        border-radius: 10px;
        background: #D2D8EE;
    }
    h2{
        color: #0A2B45;
        font-family: Roboto;
        font-size: 40px;
        font-weight: 550; 
        padding-top: 1%;       
    }
  }
  .info{
    h5{
        color: #0A2B45;
        font-family: Roboto;
        font-size: 25px;
        font-weight: 500; 
        margin-left: 1%; 
    }
        p{
            color: rgba(0, 0, 0, 0.80);
            font-family: Ubuntu;
            font-size: 20px;
            font-weight: 400;  
            margin-left: 2%;  
            width: 80%;       
        }       
}
        .adiv{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        a{
            color: #EB474F;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 600;  
            margin-top: 1%;          
        }
        .conth3{
            color: #0A2B45;
            font-family: Roboto;
            font-size: 40px;
            font-weight: 500;          
        }
    .contact{
        .error{
            color: red;
        }
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 20px;
        h6{
            color: #0A2B45;
            font-family: Roboto;
            font-size: 25px;
            font-weight: 500;
        }
        // p{
        //     color: #000;
        //     font-family: Roboto;
        //     font-size: 20px;
        //     font-weight: 400;           
        // }
       .message{
        padding-left: 25px;
        padding-bottom: 20px;
        padding-top: 5px;
        border-radius: 10px;
        background: #F7F8FB;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .svgdisplay{
            display: flex;
            align-items: center;
            gap: 15px;

            .paragraph{
                width: 90%;
                font-size: 17px;
            }
            .svg{
            background: #f3f1f1;
            height: 65px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            svg{
                width: 30px;
                height: 30px;
           }
            }
        }
    }
       .whatsapp{
        background: #F7F8FB;
        padding-left: 20px;
         width: 65%;
         padding-bottom: 20px;
         padding-top: 5px;
         border-radius: 10px;
        
        .svgdisplay{
            display: flex;
            align-items: center;
            gap: 15px;

            p{
                width: 90%;
                font-size: 17px;
            }
            .svg{
            background: #f3f1f1;
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            svg{
                width: 35px;
                height: 35px;
           }
            }
        }
        .more{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;
            width: 90%;
            p{
            font-size: 17px;
            font-weight: 600;
            color: #1a1a1a;
        }
        }
        img{
            width: 18px;
       } 
        .icons{
          display: flex;
          gap:5px;
          align-items: center;
        }  
        .contlines{
            height: 2px;
            background-color:  rgba(10, 43, 69, 0.50);
            border: none;
            width: 90%;
        } 
   }
}

@media screen and (max-width: 1600px) {
    .help{
        input{
         width: 1000px;
        }
    }
}

@media screen and (max-width: 992px) {
    .help{
        margin-top: -2.7%;
        input{
            width: 550px;
        }
        h1{
            font-size: 35px;
        }
        .divhelp{
            margin-left: 10%;
        }
    }
    
 }
 @media screen and (max-width: 480px) {
    .grid{
        padding: 0 10px;
    }
    .help{
        margin-top: -7.6%;
        height: 150px;
        input{
            width: 190px;
            font-size: 14px;
            height: 35px;
        }
        h1{
            font-size: 20px;
        }
        .divhelp{
            margin-left: 10%;
        }
        .button{
            width: 150px;
            height: 37px;
        }
    }
    .divsection{
        h2{
          font-size: 20px;
        }
    }
    .info{
        h5{
            font-size: 16px;
        }
        p{
            font-size: 14px;
        }
    }
    .resetdiv{
        p{
            font-size: 14px;
        }
        svg{
            width: 20px;
        }
    }
    a{
        font-size: 14px;
    }
    .conth3{
        font-size: 20px;
    }
    .contact{
        display: flex;

        p{
            font-size: 14px;
        }
        h6{
            font-size: 16px;
        }
        svg{
            width: 20px;
        }
        .message{
            width: 100%;
            svg{
                width: 30px;
            }
         }
         .whatsapp{
             width: 100%;
         }
    }
}
}