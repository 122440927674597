/// Clamps, aka truncates, multi-line text. Note that non-webkit browsers will
/// not see the ellipsis ¯\_(ツ)_/¯
/// @param {Value}          $font-size     - Font size of the text
/// @param {Unitless Value} $line-height   - Line height of the text; **must be a unitless value**
/// @param {Number}         $lines-to-show - Number of lines to show
/// @example scss
/// p {
///   @include line-clamp($font-size: 16px, $line-height: 1.5, $lines-to-show: 3);
/// }
@mixin line-clamp($font-size, $line-height, $lines-to-show) {
  @if unitless($line-height) == false {
    $line-height: create-unitless-line-height($font-size, $line-height);
  }

  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  font-size: $font-size;
  height: (
    $font-size * $line-height * $lines-to-show
  ); // Fallback for non-webkit browsers
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}

@mixin typography-primary($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  //font-family: 'Roboto', sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: $color ;
  display: flex;
  flex-direction: row;
  align-items: center;
}
