@import '../../../utils/utils.scss';

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .formLabel {
    width: 100%;
    height: 10vh;
    @include typography-primary(1.5em, bold, #1a1a1a);
    justify-content: center;
  }
  .formBody {
    width: 100%;
    height: 83vh;
    overflow: scroll;
    padding-bottom: 10vh;
    .segmentContainer {
      width: 100%;
      height: auto;
      display: block;
    }
    .save {
      height: 10vh;
      padding: 10px 0.7em;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-inline-end: 20px;
      justify-content: flex-end;
      border-top: 1px solid #1a1a1a96;
      ion-button {
        height: 100%;
        width:20vh;
        margin: 0;
      }
      .errorHolder {
        height: 100%;
        width: 50%;
        @include typography-primary(1em, bold, #A94064);
      }
    }
  }
}
