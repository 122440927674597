@import '../../../utils/utils.scss';

.holder {
  .desktop {
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
      }
    }
    .container {
      height: 100%;

      .productCardHolder {
        width: 95%;
        height: auto;
        margin: 1% auto;
      }
      .recomHolder {
        width: 95%;
        height: auto;
        margin: 1% auto;
      }
    }
  }
}
.loading {
  position: relative;
  width: 100%;
  height: 100%;
}

.storeCard {
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  .profile {
    position: relative;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner,
    .profileImg {
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .banner {
      position: relative;
      width: 100%;
      height: 70%;
      background: #1a1a1a;
      overflow: hidden;
    }
    .profileImg {
      position: absolute;
      width: 10vw;
      height: 10vw;
      display: flex;
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: white;
      overflow: hidden;
      top: 30%;
    }
  }
  .info {
    position: relative;
    width: 100%;
    height: 60%;
    .infogrid {
      padding: 2% 5%;
      width: 100%;
      ion-row {
        width: 100%;
        ion-col {
          height: 100%;
          padding: 0;
        }
      }
      .row1 {
        height: 30%;
        .name {
          .title {
            width: 100%;
            height: 65%;
            @include line-clamp(
              $font-size: 1.2em,
              $line-height: 1.5,
              $lines-to-show: 1
            );
            @include typography-primary(1.5em, bold, #1a1a1a);
          }

          .type {
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            @include typography-primary(small, 400, rgba(0, 0, 0, 0.635));
            ion-icon {
              margin: 0 3px;
            }
          }
        }
        .rank {
        }
      }
      .row2 {
        height: 50%;
        margin-top: 8px;
        .contacts,
        .locations {
          height: 100%;
          .label {
            width: 100%;
            height: 10%;
            @include typography-primary(small, bold, #1a1a1a);
          }
          .list {
            display: flex;
            flex-direction: column;
          }
        }
        .contacts {
          .contact {
            width: 100%;
            margin: 3px 0;
            @include typography-primary(small, medium, #1a1a1a);
          }
        }
        .locations {
          .location {
            width: 100%;
            margin: 3px 0;
            @include typography-primary(small, medium, #1a1a1a);
          }
        }
      }
    }
  }
}

.productCard {
  .desktopGrid {
    ion-row {
      width: 100%;
      ::-webkit-scrollbar {
        display: none !important;
      }
      ion-col {
        height: 100%;
        padding: 0;
      }
    }
    .info {
      .title {
        @include typography-primary(1.3em, 500, #1a1a1a);
        line-height: 1.2;
      }
    }
    .price {
      @include typography-primary(1.3em, bold, var(--ion-color-secondary));
    }
    .storeDetails {
      .content {
        a {
          @include typography-primary(1.3em, bold, var(--ion-color-primary));
          text-decoration: none;
        }
        .locations,
        .contacts {
          .label {
            @include typography-primary(1em, bold, var(--ion-color-primary));
          }
          .list {
            .item {
              @include typography-primary(1em, 300, var(--ion-color-primary));
            }
          }
        }
      }
    }
    .productDetails,
    .storeDetails {
      .head {
        width: 100%;
        .label {
          @include typography-primary(1.1em, bold, #1a1a1a);
        }
      }
      .details {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .grid {
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
        padding: 0;
      }
    }
    .info {
      .title {
        @include typography-primary(1.2em, 500, #1a1a1a);
        line-height: 1.1;
      }
    }
    .category {
      @include typography-primary(1em, 400, rgba(0, 0, 0, 0.5));
    }
    .price {
      @include typography-primary(1.5em, bold, var(--ion-color-secondary));
    }

    .storeDetails {
      .content {
        a {
          @include typography-primary(1.3em, bold, var(--ion-color-primary));
          text-decoration: none;
        }
        .locations,
        .contacts {
          .label {
            @include typography-primary(1em, bold, var(--ion-color-primary));
          }
          .list {
            .item {
              @include typography-primary(1em, 300, var(--ion-color-primary));
            }

          }
        }
      }
    }
    .productDetails,
    .storeDetails {
      .head {
        width: 100%;
        .label {
          @include typography-primary(1.1em, bold, #1a1a1a);
        }
      }
      .details {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.recomCard {
  .similar {
    margin-top: 15px;
  }
  .sameStore,
  .similar {
    height: auto;
    width: 100%;

    .label {
      height: 20%;
      align-items: center;
      display: flex;
      margin: 3px 8px;
      @include typography-primary(medium, bold, var(--ion-color-primary));
    }
    ::-webkit-scrollbar {
      height: 1vh;
    }
    ._list {
      .listItem {
        flex-basis: auto;
      }
    }
  }
}
