@import '../../../../utils/utils.scss';

.menu-items {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-right: 0.5px solid rgb(0 0 0 / 10%);

	ion-item {
		color: var(--ion-color-primary);
		font-size: 1.1rem;
		font-weight: 600;
		margin: 2px 0;
		ion-icon {
			color: var(--ion-color-primary);
			margin: 12px 16px 12px 5px;
			font-size: 2rem;
		}
		ion-label {
			color: var(--ion-color-primary);
			font-weight: 600;
		}
	}
	.selected {
		color: var(--ion-color-secondary);
		ion-icon {
			color: var(--ion-color-secondary);
		}
		ion-label {
			color: var(--ion-color-secondary);
		}
	}
	ion-item:last-child {
		margin-top: auto;
	}
}
