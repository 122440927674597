@layer components {
  .highlight_primary {
    @apply font-extrabold m-0 box-decoration-clone rounded-lg;
    // padding: 0.6em 16px;
    background: linear-gradient(
        104deg,
        rgba(26, 26,26, 0) 0.9%,
        rgba(26,26,26, 1.25) 2.4%,
        rgba(26,26,26, 0.5) 5.8%,
        rgba(26,26,26, 0.1) 93%,
        rgba(26,26,26, 0.7) 96%,
        rgba(26,26,26, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(26,26,26, 0) 0%,
        rgba(26,26,26, 0.3) 7.9%,
        rgba(26,26,26, 0) 15%
      );
    text-shadow: -12px 12px 9.8px rgba(26,26,26, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
  .highlight_secondary {
    @apply font-extrabold m-0 box-decoration-clone rounded-lg;
    // padding: 0.6em 13.7px;
    background: linear-gradient(
        104deg,
        rgba(235, 71, 79, 0) 0.9%,
        rgba(235, 71, 79, 1.25) 2.4%,
        rgba(235, 71, 79, 0.5) 5.8%,
        rgba(235, 71, 79, 0.1) 93%,
        rgba(235, 71, 79, 0.7) 96%,
        rgba(235, 71, 79, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(235, 71, 79, 0) 0%,
        rgba(235, 71, 79, 0.3) 7.9%,
        rgba(235, 71, 79, 0) 15%
      );
    text-shadow: -12px 12px 9.8px rgba(235, 71, 79, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
  .highlight_mark {
    @apply mx-[-0.4em] py-[0.1em] px-[0.4em] rounded-[0.8em] rounded-r-[0.3em] bg-transparent box-decoration-clone;
    padding: 0.6em 13.7px;
    background-image: linear-gradient(
      to left,
      rgba(235, 71, 79, 0.1),
      rgba(235, 71, 79, 0.7) 4%,
      rgba(235, 71, 79, 0.3),
      rgba(235, 71, 79, 0.7) 96%,
    );
  }
}
