@import '../../utils/utils.scss';

.main {
  position: relative;
  width: 100%;
  height: 100%;
  .options {
    width: 100%;
    height: 10%;
    //border-bottom: 1px solid #1a1a1a;
    padding: 1% 0;
    display: flex;
    justify-content: end;
    ion-button {
      text-transform: capitalize;
    }
  }
  .bgraph {
    position: relative;
    width: 100%;
    height: 90%;
    overflow-x: scroll;
    overflow-y: hidden;
    .message {
      width: 100%;
      height: 100%;
      @include typography-primary(1em, 400, var(--ion-text-color));
      justify-content: center;
    }
  }
}
.graphModal {
  .header {
    width: 100%;
    height: 10%;
    @include typography-primary(1.3em, bold, var(--ion-color-primary));
    justify-content: space-between;
    padding: 0.5% 2%;
    p {
    }
    ion-button {
      height: 100%;
      width: auto;
    }
  }
  .container {
    width: 100%;
    height: 90%;
    padding: 2%;
    .graph {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      .message {
        width: 100%;
        height: 100%;
        @include typography-primary(1em, 400, var(--ion-text-color));
        justify-content: center;
      }

      .cont {
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .graphModal {
    --width: 70vw !important;
    --height: 90vh !important;
  }
}
