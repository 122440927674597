@mixin line-clamp($numLines: 1, $lineHeight: 90%) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block !important;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight;
}

.container {
  box-shadow: none;
  border: 1px solid #e0e0e0;
  ion-card-content {
    padding: 3%;
    height: 100%;

    ion-grid {
      padding: 0;
      height: 100%;
      ion-row {
        width: 100%;
        ion-col {
          padding: 0;
        }
      }
      .topBar {
        height: 55%;
        .head {
          height: 100%;
          ion-img {
            border-radius: 20px;
            overflow: hidden;
            object-fit: cover;
          }
        }
        .name {
          height: 100%;
          width: 100%;
          padding: 0 5%;
          display: flex;
          align-items: center;
          color: #1a1a1a;
          font-weight: bold;
          font-family: "Roboto", sans-serif;
        }
      }
      .itemList {
        height: 45%;
        .item {
          height: 100%;
          ion-img {
            overflow: hidden;
            object-fit: cover;
            border-radius: 10px;
          }
          div {
            height: 20%;
            width: 90%;
            margin: 0 auto 0 0;
            overflow: hidden;
            white-space: nowrap;
            color: #1a1a1a;
            font-weight: bold;
            font-family: "Roboto", sans-serif;
            @include line-clamp;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90vw;
    height: 36vh;
    ion-card-content {
      ion-grid {
        .topBar {
          .head {
            ion-img {
              height: 17vh;
              width: 35vw;
              border: 1px solid rgba(0, 0, 0, 0.25);
            }
          }
          .name {
            font-size: 1.3rem;
          }
        }
        .itemList {
          .item {
            ion-img {
              width: 20vw;
              height: 10vh;
              margin: 8% 0;
            }
            div {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .container {
    height: 40vh;
    width: 29vw;
    ion-card-content {
      ion-grid {
        .topBar {
          .head {
            padding: 2%;
            ion-img {
              height: 100%;
              width: 100%;
            }
          }
          .name {
            font-size: 1.5rem;
          }
        }
        .itemList {
          .item {
            padding: 2%;
            ion-img {
              width: 90%;
              height: 70%;
            }
            div {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
