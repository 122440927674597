@import '../../utils/utils.scss';

.ProductsCard {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 1% 2%;
  .tabHeader::-webkit-scrollbar {
    display: none !important;
  }
  .tabHeader {
    position: relative;
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    border-bottom: 1px solid #1a1a1a;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .button {
      height: 100%;
      width: 160px !important;
      @include typography-primary(0.9em, bold, #1a1a1a);
      margin: 0;
      justify-content: center;
    }
    .selector {
      position: absolute;
      width: 160px;
      background: #a94064;
      height: 4px;
      top: 93%;
    }
  }
  .tabs {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 15em;
    justify-content: start;
    padding-bottom: 2em;
  }
}
