ion-modal {
  --min-width: 50%;
  --max-width: 90%;
  --height: 50%;
  --max-height: 80%;
  --backdrop-opacity: 0.7;
  --border-radius: 20px;

  ion-header {
    background: var(--ion-color-primary);
    ion-toolbar {
      --background: var(--ion-color-primary);
      --padding-start: 20px;
      --padding-end: 20px;

      ion-text {
        display: flex;
        align-items: center;
        ion-icon {
          margin-right: 5px;
          font-size: 1.5rem;
        }
        span {
          color: white;
          width: fit-content;
          font-size: 1.2rem;
        }
        .span2 {
          color: var(--ion-color-secondary);
          font-style: italic;
        }
      }
      .close-icon {
        color: white;
        font-size: 1.5rem;
      }
    }
  }
  
  ion-content {
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-top: 20px;

    h2 {
      font-family: "Roboto", sans-serif;
      color: #1a1a1a;
      font-size: 1.5rem;
      font-weight: 700;
    }
    .msg-wrapper {
      margin: 0 20px;
      font-family: "Roboto", sans-serif;
      color: #1a1a1a;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        line-height: 1.8;
        .proceed-text {
          font-weight: bold;
          font-size: 1.1rem;
          display: block;
        }
      }
      .error {
        color: red;
      }

      ion-spinner {
        color: #A94064;
        width: 80px;
        height: 80px;
      }
    }

    .btns,
    .desktop-btns {
      display: flex;
      justify-content: space-around;
      margin: 20px 30px;
      ion-row {
        width: 100%;
        ion-col {
          display: flex;
          justify-content: center;
          ion-button {
            text-transform: none;
            font-weight: bold;
            font-size: 1rem;
            width: 100%;
            --background: var(--ion-color-secondary);
            --color: white;
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 10px;
            --padding-bottom: 10px;
            --box-shadow: none;
          }
          .cancel-btn {
            --background: #C0BFBF;
            --color: rgb(30, 29, 29);
            --border: 1px solid var(--ion-color-secondary);
          }
        }
      }
    }
    .desktop-btns {
      margin: 20px 0;
    }
  }
}
.mobile-modal {
  --min-height: 70%;
}
.mobile-modal-loading {
  --min-height: 85%;
}
.desktop-modal-loading {
  --min-height: 65%;
}

