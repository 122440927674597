$font: 'Roboto', sans-serif;
.Btn{
height:6vh;
fill: solid;
}
.desktop{
    width: 100%;
    height: 100%;
    display : flex;
    justify-content : center;
    align-items : center;
  
    section{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    }
    form{
       width: 25%;
       h1{
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #1a1a1a;
       }
       .error{
         color: red !important
       }
       p{
         text-align: center;
         font-weight: bold;
         color: #1a1a1a;
       }
       h5{
        color: #1a1a1a;   
       }
       a{
          text-decoration: none;
       }
       .resend{
          width: 4vw;
          height: 2vh;
          cursor: pointer;
          color: transparent;
       }
    }
}