@import '../../../../utils/utils.scss';

.menu {
	--side-max-width: 20%;
	border-right: 0.5px solid rgb(0 0 0 / 10%);

	ion-content {
		--background: white;
		--padding-top: 10px;
	}
}
.content-wrapper,
:host.admin-slide-menu {
	display: flex;
	flex-direction: column;
	height: 100%;
	background: white;

	.m-top {
		margin-top: 20px;
	}

	.logo-col {
		display: flex;
		align-items: center;
		.title-wrapper {
			display: flex;
			align-items: center;
			font-size: 1.2rem;
			font-weight: bold;
			color: var(--ion-color-primary);
		}
		ion-icon {
			width: 40px;
			height: 40px;
		}
		.secondary {
			color: var(--ion-color-secondary);
			font-style: italic;
			margin-right: 5px;
		}
	}
	.close-col {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		ion-icon {
			font-size: 1.5em;
			width: 40px;
			height: 40px;
			--ionicon-stroke-width: 35px;
			color: var(--ion-color-secondary);
		}
	}

	.user-details-row {
		ion-col {
			padding: 15px;
			ion-item {
				--padding-top: 3px;
				--padding-bottom: 3px;
				--padding-start: 10px;
				--background: #e6e5e5;
				border-radius: 10px;
				div {
					font-weight: 600;
					font-size: 1.3rem;
					background: white;
					color: var(--ion-color-primary);
					border-radius: 10px;
					padding: 2px 8px;
					margin-right: 10px;
					text-transform: uppercase;
				}
				ion-label {
					font-weight: 600;
					font-size: 1.1rem;
					text-transform: capitalize;
				}
				ion-icon {
					font-size: 2rem;
					margin-right: 10px;
					color: var(--ion-color-primary);
				}
			}
		}
	}

	.menu-items {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		ion-item {
			color: var(--ion-color-primary);
			font-size: 1rem;
			font-weight: 600;
			margin: 0;
			ion-icon {
				color: var(--ion-color-primary);
				margin: 0px 15px 0px 10px;
				font-size: 2rem;
			}
			ion-label {
				color: var(--ion-color-primary);
				font-weight: 600;
			}
		}
		.selected {
			color: var(--ion-color-secondary);
			ion-icon {
				color: var(--ion-color-secondary);
			}
			ion-label {
				color: var(--ion-color-secondary);
			}
		}
		.last-item {
			margin-top: auto;
		}
		.approvals-wrapper {
			margin-top: 20px;
			.divider {
				width: 90%;
				margin: 0 auto;
				background: var(--ion-color-primary);
				opacity: 0.9;
				height: 0.5px;
				border: none;
			}
			.approval-item {
				--padding-start: 30px !important;
				--padding-end: 20px;
				ion-icon {
					font-size: 1.5rem;
					margin-right: 10px;
				}
				ion-label {
					font-size: 1rem;
				}
			}
			.approvals-menu {
				padding: 0 30px;
				ion-item {
					--padding-start: 30px !important;
					--padding-end: 20px;
					ion-icon {
						font-size: 1.3rem;
						margin-right: 10px;
					}
					ion-label {
						font-size: 1rem;
					}
				}
			}
		}
	}
}
