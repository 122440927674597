.main {
  width: 100%;
  height: 100%;
  overflow: hidden;

  //desktop
  .searchGrid {
    width: 98%;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    ion-row {
      width: 100%;
      ion-col {
        position: relative;
        padding: 0;
      }
    }
    .holder {
      height: 100%;
      .leftBar {
        height: 100%;
        overflow: hidden;
        .topBar {
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 6vh;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .title {
            align-items: center;
            font-size: 1em;
            font-family: 'Roboto' sans-serif;
            width:auto;
          }
          .btn{
            height:100%;
            width:auto;
            ion-button{
               font-weight: bold;
            }
           
          }
        }
        .filters {
          width: 100%;
          height: fit-content;
          overflow: hidden;
          padding: 0 0 25px 0;
          border-right: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
      .rightBar {
        height: 100%;
        overflow: hidden;
        .topBar {
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 6vh;
          padding: 0.2em 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .segment {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            height: 90%;
            width: 9.5%;
          }
        }
        .content {
          width: 100%;
          min-height: 100%;
          border-left: 1px solid rgba(0, 0, 0, 0.3);
          .searchField {
            width: 95%;
            height: auto;
            margin: 1em auto;
            border-bottom: 1px solid rgba(0, 0, 0, 0.534);
            padding: 0.5em 2em;
          }
          .noResults {
            width: 95%;
            height: auto;
            display: flex;
            align-items: center;
            margin: 1em auto;
            justify-content: center;
          }
          .searchResults {
            width: 95%;
            height: auto;
            margin: 1em auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
          
          }
        }
      }
    }
  }

  //mobile
  .mobileSearchGrid {
    width: 98%;
    height: 100%;
    padding: 0;
    margin: 1em auto;
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
      }
    }

    .headBar {
      width: 100%;
      height: 15vh;
      padding: 0 1em;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.534);
      flex-direction: column;
      justify-content: space-between;
      .searchBar {
        width: 100%;
        height: 40%;
        padding: 2px;
      }
      .filters {
        width: 100%;
        height: 50%;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.2em 0;

        span {
          font-size: 1.1em;
          font-weight: bold;
          font-family: 'Roboto', sans-serif;
          color: #1a1a1a;
          ion-button {
            height: 100%;
          }
        }
      }
    }
    .content {
      width: 100%;
      height: auto;
      padding: 0 0.8em;
      .displaySegment {
        position: relative;
        width: 100%;
        height: 6vh;
        margin: 0.2em;
        display: flex;
        align-items: center;
        justify-content: end;
        .segment {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          height: 90%;
          width: 30%;
        }
      }
      .results {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .noResults {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

//Loading Component
.loadingBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .sidebar {
    width: 30%;
    height: 100%;
    margin: 2em 0;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
  }
  .main {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
    margin: 1em 0;
    .holder {
      margin: 5px auto;
    }
  }
}
.mobileLoadingBody {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  .filters {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
    margin: 1em 0;
    .holder {
      margin: 5px auto;
    }
  }
}
