@import '../../../utils/utils.scss';

.pageContainer {
  .container {
    .headlabel {
      @include typography-primary(1.3em, bold, var(--ion-color-primary));
    }
    .content {
      .itemLabel {
        @include typography-primary(1em, bold, var(--ion-color-primary));
      }
      .item {
        @include typography-primary(1em, bold, unset);
      }
    }
  }
}

//components

.metrics {
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #1a1a1a71;
  padding: 2% 3%;
  overflow: hidden;
  .grid {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ion-row {
      width: 100%;
      ion-col {
        padding: 0;
        height: 100%;
      }
    }
    .label {
      height: 6%;
      div {
        width: 100% !important;
        height: 100%;
        @include typography-primary(1.1em, bold, #1a1a1a);
        justify-content: center;
      }
    }
    .impressions,
    .engagements {
      ion-col {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .heading {
        position: relative;
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1% 2%;
        cursor: pointer;
        .name {
          @include typography-primary(0.8, bold, #1a1a1a);
        }
        .observations {
          @include typography-primary(0.9em, bold, #a94064);
        }
      }
      .chart {
        width: 100%;
        height: 76%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2%;
        border: 1px solid #1a1a1a61;
      }
    }
    .impressions {
      height: 35%;
    }
    .engagements {
      height: 35%;
    }
    .productTags {
      position: relative;
      height: 24%;
      ion-col {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      .heading {
        position: relative;
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1% 2%;
        .name {
          @include typography-primary(0.8, bold, #1a1a1a);
        }
        .action {
          height: 100%;
          width: 10%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          ion-icon {
            height: 100%;
            width: 100%;
          }
        }
      }
      .tags {
        width: 100%;
        height: 76%;
        padding: 0 2%;
        .list {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex-basis: fit-content;
          justify-content: space-between;
          .item {
            width: fit-content;
            height: fit-content;
            border: 3px solid #a94064;
            border-radius: 15px;
            padding: 2px 3px;
            @include typography-primary(1em, bold, #1a1a1a);
            margin-bottom: 3px;
          }
        }
        .add {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          @include typography-primary(1em, bold, #00000061);
          cursor: pointer;
          border: 2px dashed #00000044;
        }
      }
    }
  }
  .backdrop::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    cursor: default;
    content: '';
    background: transparent;
  }
  .backdrop:focus {
    outline: none;
    box-shadow: none;
  }
  .addTag {
    position: absolute;
    width: 90%;
    height: auto;
    background: white;
    border-radius: 10px;
    border: 1px solid #1a1a1a71;
    padding: 2%;
    top: 25%;
    left: 5%;
    z-index: 2;
    .close {
      width: 100%;
      height: 3vh;
      display: flex;
      align-items: center;
      justify-content: end;
      .btn {
        position: relative;
        height: 100%;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        ion-icon {
          height: 100%;
          width: 100%;
        }
      }
    }
    .input {
      height: auto;
      width: 100%;
      padding: 0 2%;
      .field {
        width: 100%;
        height: 5vh;
        border: none;
        border-radius: 5px;
        outline: none;
        padding-inline-start: 2%;
        background: #ebebeb;
        @include typography-primary(1em, bold, #1a1a1a);
      }
      .field:focus {
        border-bottom: 1px solid #1a1a1a;
      }
      .field::placeholder {
        font-weight: 400;
      }
      .suggestions {
        width: 90%;

        max-height: 15vh;
        margin: 5px auto;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
        flex-direction: column;
        padding: 3px 5px;
        overflow-y: scroll;
        .item {
          position: relative;
          width: 100%;
          padding: 3px 5px;
          @include typography-primary(0.8em, 450, #1a1a1a);
          border-bottom: 1px solid #1a1a1a49;
          cursor: pointer;
        }
        .item:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .actions {
      height: 5vh;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 2%;
      margin: 5px 0;
      justify-content: end;
      ion-button {
        height: 100%;
        width: 100%;
        font-weight: bold;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
