@import '../../../../../utils/utils.scss';

.footer {
  position: relative;
  min-height: 20vh;
  background: var(--ion-color-tertiary-shade);
  padding: 2%;
  .linkGrp {
    width: 90%;
    height: 40%;
    margin: 0 auto;
    color: white;
    h2 {
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      margin: 2%;
    }
    .list {
      margin: 0;
      list-style: none;
      height: auto;
      width: 100%;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
      background: transparent;
      .item {
        --background: transparent;
        --min-height: 40%;
        width: 100%;
        margin: 4% 0;
        * {
          color: rgba(255, 255, 255, 0.7);
          font-size: 1rem;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
  .info {
    width: 90%;
    margin: 3% auto;
    ion-col {
      padding: 0;
    }
    .logo {
      height: 1.6em;
      display: flex;
      align-items: center;
      width: 100%;
      ion-icon {
        height: 100%;
        aspect-ratio: 1/1;
        width: auto;
        margin: 0 2%;
      }
      span {
        @include typography-primary(1em, 600, white);
        width: fit-content;
      }
      .span2 {
        color: var(--ion-color-secondary);
        font-style: italic;
      }
    }
    h2,
    h3,
    div,
    p {
      color: white;
      font-family: 'Roboto', sans-serif;
      margin: 2% 0;
    }
    .com {
      div {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 3% 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
      p {
        color: #c7c7c7;
        font-size: 0.95rem;
      }
      h3 {
        font-weight: bold;
        font-size: 1.1rem;
        margin: 3% 0;
        color: #f1f1f1;
      }
    }
    .newsletter {
      h2 {
        font-weight: bold;
        font-size: 1.2rem;
      }
      p {
        font-size: 0.95rem;
        color: #c7c7c7;
      }

      .input {
        position: relative;
        top: 5%;
        --background: transparent;
        --padding-start: 0;
        margin: 0;
        padding: 0;
        .field {
          --background: white;
          width: 68%;
          border-radius: 5px;
          margin-right: 2%;
          .icon {
            margin: 0 3px 0 8px;
          }
        }
        .btn {
          height: 90%;
          width: 30%;
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }
    .icons {
      margin: 5% auto;
      height:auto;
      .head {
        font-weight: bold;
        font-size: 1.1rem;
      }
      .list {
        display: flex;
        flex-direction: row;
        background: transparent;
        margin: 0;
        padding: 0;
        .btn {
          --background: transparent;
          --padding-end: 5px;
          --padding-start: 5px;
        }
      }
    }
  }

}
