.header{
    padding: 15px;
}
.table{
    overflow-x: auto;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    height: 100vh;
    overflow-y: auto;
    background-color: white;
    
    table{
        border-collapse: collapse;
        border-spacing: 0;
         width:1200px ;
         .drop_down{
            display: flex;
            justify-content: center;
            align-items: center;
         }
         .footer{
            font-weight: 600;
            padding-left: 30px;
            background-color:white; 
            position: fixed;
            z-index: 1;  
            bottom: 0;  
            width: auto;
        
            }

         .spinner{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
         }
         thead, tfoot{
            position: sticky;
            z-index: 1;
            top: 0;
        }
        tfoot{
            bottom: 0;
        }
        tr{
            border-bottom: 1px solid #ddd;
        }
    th, td{     
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 500;
        font-size: 0.9em;
    }
    th{
        font-size: 1em;
        font-weight: 800;  
        background-color:#1a1a1a; 
        height: 20px;
        color: white;    
    }
    .input_sec{
        display: flex;
        gap: 7px;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 30px;
        input{
            width: 10px;
        }
    }
    .name{
        text-transform: capitalize;
    }
    .view{
        background-color:  #a94064;
        color: white;
        font-weight: 600;
        width: 50px;
        height: 20px;
        border-radius: 5px;
    }
    .id{
        width: 50px;
        padding-left: 30px;
    }
   }
  }

@media screen and (min-width: 768px) {
        .table{
        height: 80vh;
        table{
        width: 100%;
    }
    }
}