@import '../../../../utils/utils.scss';

.desktop {
  height: 100%;
  width: 100%;
  .holder {
    height: 100%;
    width: 100%;
    ion-row {
      width: 100%;
      height: 100%;
      ion-col {
        height: 100%;
        padding: 0;
      }
      .logo {
        padding-inline-start: 15px;
        .holder {
          height: 100%;
          display: flex;
          align-items: center;
          width: auto;
          cursor: pointer;

          span {
            @include typography-primary(1.3em, 600, white);
            width: fit-content;
          }
          .span2 {
            color: var(--ion-color-secondary);
            font-style: italic;
          }
        }
      }
      .navlinks {
        height: 100%;
        margin-left: auto;

        ion-row {
          padding-inline-end: 10px;
          justify-content: end;
          .navItem:hover {
            text-decoration: underline;
          }
          .navItem {
            height: 100%;
            cursor: pointer;
            @include typography-primary(1em, 600, 8);
            justify-content: center;
          }
        }
      }
      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .holder {
          width: 90%;
          height: 75%;
        }
      }
      .storeMenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .chip {
          display: flex;
          width: 8vw;
          height: 100%;
          align-items: center;
        }
        .btnGrp {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          ion-buttons {
            width: 100%;
            height: 100%;
            justify-content: space-evenly;
            ion-button {
              font-weight: bold;
            }
            .two {
              width: 7vw;
              --border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.mobile {

  ion-row {
    padding: 0 3%;
    background: white;
    border-bottom: 1px solid #e2e3e5;
    ion-col,
    .logo-wrapper {
      display: flex;
      align-items: center;
    }
    .logo-col {
      padding: 4% 0;
      width: auto;
      ion-icon {
        font-size: 1.8rem;
      }
      span {
        color: var(--ion-color-primary) !important;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .span2 {
        color: var(--ion-color-secondary) !important;
        font-style: italic;
      }
    }
    .chip {
      width: 50px;
    }
    .menu-col {
      display: flex;
      justify-content: flex-end;
      ion-icon {
        font-size: 2rem;
      }
    }
  }
}
