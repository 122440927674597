@import "../../../utils/utils.scss";

.productFlex {
  padding: 0.8em;
  border: 1px solid #1a1a1a4d;
  border-radius: 7px;
  .overlay {
    position: absolute;
    z-index: 1;
    width: auto;
    height: 100%;
    cursor: pointer;
    div {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .contentHolder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .img {
      position: relative;
      height: 100%;
      width: 40%;
      .holder {
        width: 100%;
        height: 100%;
        padding: 0.5em 0;
        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .info {
      position: relative;
      height: 100%;
      width: 60%;
      .holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        div {
          overflow: hidden;
        }
        .product {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .title {
            position: relative;
            height: 20%;
            width: 100%;
            @include typography-primary(
              $size: 0.9em,
              $weight: bold,
              $color: #1a1a1a
            );
            @include line-clamp(
              $font-size: 0.9em,
              $line-height: 1.2,
              $lines-to-show: 1
            );
          }
          .shortDescription {
            position: relative;
            height: 35%;
            width: 100%;

            @include typography-primary(
              $size: 0.8em,
              $weight: light,
              $color: rgba(0, 0, 0, 0.6)
            );
            @include line-clamp(
              $font-size: 0.8em,
              $line-height: 1.2,
              $lines-to-show: 4
            );
          }
        }

        .price {
          position: relative;
          height: 20%;
          width: 100%;
          @include typography-primary(
            $size: 1.5em,
            $weight: bold,
            $color: rgba(15, 50, 77, 0.8)
          );
        }
        .store {
          position: relative;
          height: 10%;
          width: 100%;
          @include typography-primary(
            $size: 0.9em,
            $weight: 500,
            $color: #A94064
          );
        }
        .location {
          position: relative;
          height: 10%;
          width: 100%;
          @include typography-primary(
            $size: 0.85em,
            $weight: 500,
            $color: rgba(0, 0, 0, 0.6)
          );
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .shortDescription {
    height: 25%;
    @include line-clamp(
      $font-size: 0.8em,
      $line-height: 1.3,
      $lines-to-show: 3
    );
  }
  .price {
    height: 30% !important;
  }
}
